<template>
  <div class="visits">
    <MobileTable
      v-model:selection="store.state.visit.selectedVisits"
      label="Listado de Visitas Progamadas"
      :columns="visit.columns"
      :data="store.state.visit.visits"
      :linked="true"
      :onSelect="onDetail"
    />
    <DesktopTable
      v-model:selection="store.state.visit.selectedVisits"
      label="Listado de Visitas Progamadas"
      :columns="visit.columns"
      :data="store.state.visit.visits"
      :linked="true"
      :onSelect="onDetail"
    />
    <Actions :items="visit.actions" />
    <BasicModal v-model:visible="visit.showModal">
      <template v-slot:dialog>
        <BasicTitle title="Detalle de la visita" />
        <div class="container-form">
          <BasicLabel label="Nombre" />
          <BorderInput :label="visit.data.name" :disabled="true" />
          <FormError />
          <BasicLabel label="CI" />
          <BorderInput :label="visit.data.identity" :disabled="true" />
          <FormError />
          <BasicLabel label="Apartamento" />
          <BorderInput :label="visit.data.apartment" :disabled="true" />
          <FormError />
          <BasicLabel label="Fecha y Hora de Agenda" />
          <BorderInput :label="visit.data.date" :disabled="true" />
          <FormError />
          <BasicLabel label="Estado" />
          <BorderInput :label="visit.data.status" :disabled="true" />
          <FormError />
          <BasicLabel label="Comentarios" />
          <OutlinedTextArea v-model="visit.data.comment" :disabled="true" />
          <FormError />
          <!-- <BasicLogo
            width="350"
            :src="visit.data.image"
            alt="Comprobante de Pago"
          /> -->
        </div>
      </template>
    </BasicModal>
  </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import Actions from "../../components/shared/Actions.vue";
import MobileTable from "../../widgets/tables/MobileTable";
import DesktopTable from "../../widgets/tables/DesktopTable";
import BasicModal from "../../widgets/modal/BasicModal";
// import BasicLogo from "../../widgets/logo/BasicLogo";
import FormError from "../../widgets/error/FormError";
import BorderInput from "../../widgets/input/BorderInput";
import BasicLabel from "../../widgets/label/BasicLabel";
import BasicTitle from "../../widgets/title/BasicTitle";
import OutlinedTextArea from "../../widgets/textarea/OutlinedTextArea";
import { onBeforeMount, reactive } from "vue";
import store from "../../store";
import { openMode, actions, dates } from "../../constants";

export default {
  components: {
    MobileTable,
    DesktopTable,
    Actions,
    BasicModal,
    // BasicLogo,
    FormError,
    BorderInput,
    BasicLabel,
    BasicTitle,
    OutlinedTextArea,
  },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const onCreate = () => {
      store.commit("setOpenMode", openMode.CREATE);
      router.push("/visitas/nuevo");
    };
    const onDetail = (data) => {
      visit.data.name = data.name;
      visit.data.identity = data.identity;
      visit.data.apartment = data.apartment;
      visit.data.date = `${dates.dateToString(data.date)} ${dates.timeToString(
        data.date
      )}`;
      visit.data.status = data.status;
      visit.data.comment = data.comment;
      visit.showModal = true;
    };
    const onRemove = async () => {
      if (store.state.visit.selectedVisits.length !== 1) {
        store.state.toast.add({
          severity: "info",
          summary: "",
          detail: "Debe seleccionar un registro!",
          life: 5000,
        });
      } else if (
        store.state.visit.selectedVisits[0].userId !==
          store.state.auth.user.id ||
        store.state.visit.selectedVisits[0].statusId === 3
      ) {
        store.state.toast.add({
          severity: "info",
          summary: "",
          detail: "No puede eliminar la visita",
          life: 5000,
        });
      } else {
        store.commit("setLoading", true);

        const response = await store.dispatch(actions.visitActions.remove, {
          userId: store.state.auth.user.id,
          id: store.state.visit.selectedVisits[0].id,
        });

        if (response.ok) {
          store.commit("setSelectedVisits", []);
          await store.dispatch(
            actions.visitActions.findAll,
            store.state.general.selectedBuilding
          );
          store.commit("setLoading", false);
        } else {
          store.state.toast.add({
            severity: "error",
            summary: "",
            detail: response.message,
            life: 5000,
          });
          store.commit("setLoading", false);
        }
      }
    };

    const visit = reactive({
      showModal: false,
      data: {
        name: "",
        identity: "",
        apartment: "",
        date: "",
        comment: "",
        status: "",
      },
      columns: [
        {
          field: "name",
          header: "Nombre",
          sort: true,
        },
        {
          field: "identity",
          header: "CI",
          sort: true,
          center: true,
        },
        {
          field: "apartment",
          header: "Apartamento",
          sort: true,
          center: true,
        },
        {
          field: "date",
          header: "Fecha y Hora de Agenda",
          sort: true,
          date: true,
        },
        {
          field: "comment",
          header: "Comentarios",
          sort: true,
        },
        {
          field: "status",
          header: "Estado",
          sort: true,
          center: true,
        },
      ],
      actions: [
        {
          name: "Agendar Visita",
          action: onCreate,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/visitas/nuevo"),
        },
        {
          name: "Eliminar",
          action: () => onRemove(),
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/visitas/nuevo"),
        },
      ],
    });

    onBeforeMount(async () => {
      store.commit("setLoading", true);
      store.commit("setSelectedVisits", []);
      const finded = store.state.general.breadcrumbs.find(
        (item) => item.to === route.path
      );
      if (!finded)
        store.commit("addBreadcrumb", { label: route.name, to: route.path });
      await store.dispatch(
        actions.visitActions.findAll,
        store.state.general.selectedBuilding
      );
      store.commit("setLoading", false);
    });

    return { store, visit, onDetail };
  },
};
</script>

<style scoped></style>
